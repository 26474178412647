<template>
  <button
    v-if="!isLoggedIn"
    type="button"
    class="bg-pink-200 text-violet-600 font-menu p-2 rounded-md flex items-center gap-2"
    @click="loginDiscord"
  >
    Login <img src="/icons/023-discord.png" class="h-8" />
  </button>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";

const userStore = usePocketBase();
const { loginDiscord } = userStore;
const { isLoggedIn } = storeToRefs(userStore);
</script>
